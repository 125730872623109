<template>
    <div>
        <v-edit-dialog
            v-if="permissionUpdateAccountGroup"
            ref="editDialog"
            persistent
            @save="changeGroupName">
            <span class="text-link mr-2">{{ groupName }}</span>
            <icon
                name="edit"
                size="20" />
            <template #input>
                <v-form
                    ref="form"
                    v-model="valid"
                    @submit.prevent="changeGroupName">
                    <v-text-field
                        v-model="groupName"
                        :error="duplicationError"
                        :error-messages="errorMessages"
                        class="mt-3 group-name-text-field"
                        label="Group Name"
                        :rules="requiredRules" />
                    <div class="flex mb-4">
                        <button
                            type="button"
                            class="text-link"
                            @click="closeDialog">
                            Close
                        </button>
                        <styled-button
                            class="ml-5 small"
                            :loading="loading"
                            :disabled="!valid || loading">
                            SAVE
                        </styled-button>
                    </div>
                </v-form>
            </template>
        </v-edit-dialog>
        <span v-else>{{ groupName }}</span>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledButton from '@/components/globals/StyledButton';
import { requiredRules } from '@/helpers/validationRules';
import { PERMISSION_UPDATE_ACCOUNT_GROUP } from '@/helpers/globals';
import { sleep } from '@/helpers';

export default {
    components: {
        Icon,
        StyledButton
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            groupName: '',
            valid: false,
            errorMessages: [],
            duplicationError: false,
            requiredRules
        };
    },
    computed: {
        permissionUpdateAccountGroup() {
            return this.$store.getters.userHasPermission(PERMISSION_UPDATE_ACCOUNT_GROUP);
        }
    },
    watch: {
        groupName() {
            this.duplicationError = false;
            this.errorMessages = [];
        },
    },
    mounted() {
        this.groupName = this.item.name;
    },
    methods: {
        closeDialog() {
            this.groupName = this.item.name;
            this.$refs.editDialog.isActive = false;
        },
        async changeGroupName() {
            try {
                this.loading = true;
                const data = {
                    name: this.groupName
                };
                await this.$apiRepository.updateGroup(this.item.id, data);
                this.$refs.editDialog.isActive = false;
                this.$emit('callback');
                await sleep(2000);
                this.$flash('The group name has been successfully updated!', 'green');
            } catch (error) {
                console.log(error);
                const exist = 'The name has already been taken.';
                const messageExist = error.response?.data?.error?.messages[0]?.includes(exist) ?? false;
                if (messageExist) {
                    this.duplicationError = true;
                    this.errorMessages.push('Sorry, that group name already exist.');
                } else {
                    this.groupName = this.item.name;
                    this.$flash('Error updating group name!', 'red');
                }
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
<style lang="scss">
.group-name-text-field .v-messages__message {
    margin-top: -4px;
    margin-bottom: 5px;
}
</style>